// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contentful-blog-post-slug-tsx": () => import("./../../../src/pages/{ContentfulBlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-blog-post-slug-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-templates-index-archive-tsx": () => import("./../../../src/templates/index-archive.tsx" /* webpackChunkName: "component---src-templates-index-archive-tsx" */),
  "component---src-templates-tags-archive-tsx": () => import("./../../../src/templates/tags-archive.tsx" /* webpackChunkName: "component---src-templates-tags-archive-tsx" */)
}

